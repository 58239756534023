import React, { useState } from 'react';
import { Image, Text } from '@chakra-ui/react';

// Function to generate Google Street View image URL
const getStreetViewUrl = (latitude, longitude, apiKey) => {
  return `https://maps.googleapis.com/maps/api/streetview?size=600x400&location=${latitude},${longitude}&key=${apiKey}`;
};

// Function to generate Google Static Map (satellite) image URL
const getSatelliteViewUrl = (latitude, longitude, apiKey) => {
  return `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=30&size=600x400&maptype=satellite&key=${apiKey}`;
};

const ListingImage = ({ latitude, longitude, apiKey }) => {
  const [imageSrc, setImageSrc] = useState(getStreetViewUrl(latitude, longitude, apiKey));
  const satelliteImageUrl = getSatelliteViewUrl(latitude, longitude, apiKey);

  const handleImageError = () => {
    // If Street View fails to load, fallback to Satellite View
    setImageSrc(satelliteImageUrl);
  };

  return (
    <>
      <Image
        src={imageSrc}
        alt="Property Image"
        mb={2}
        borderRadius="md"
        onError={handleImageError} // Fallback to satellite view if Street View fails
      width={{ base: '100%', sm: '80%', md: '100%' }} // Responsive width
        maxWidth="500px" // Maximum width
        height='auto'
        objectFit="cover" // Ensures the image fits its container while maintaining aspect ratio
      />
      
    </>
  );
};

export default ListingImage;
