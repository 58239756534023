import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Icon } from 'leaflet';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  VStack,
} from '@chakra-ui/react';
import axios from 'axios';

const housingIcon = new Icon({
  iconUrl: 'https://img.icons8.com/plasticine/100/exterior.png',
  iconSize: [38, 45],
  iconAnchor: [22, 94],
  popupAnchor: [-3, -76],
});

const ZillowListings = () => {
  const [listings, setListings] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedListing, setSelectedListing] = useState(null);

  useEffect(() => {
    // Fetch Zillow listings from the database when the component loads
    axios.get('https://rebekah.cloud/api2/zillow_listings')
      .then((response) => {
        console.log(response.data.listings);
        setListings(response.data.listings);
      })
      .catch((error) => {
        console.error('Error fetching Zillow listings:', error);
      });
  }, []);

  const handleDrawerOpen = (listing) => {
    setSelectedListing(listing);
    onOpen();
  };

  const southFloridaCenter = [26.1336, -80.1226];

  return (
    <>
      {/* Map Component */}
      <MapContainer center={southFloridaCenter} zoom={12} style={{ height: '500px', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
        />
        {listings
          .filter(listing => listing.latitude && listing.longitude)  // Ensure coordinates exist
          .map((listing, index) => (
            <Marker
              key={index}
              position={[parseFloat(listing.latitude), parseFloat(listing.longitude)]} // Ensure they're numbers
              icon={housingIcon}
            >
              <Popup>
                <VStack spacing={'auto'}>
                  <strong>{listing.address}</strong><br />
                  <Text>Status: {listing.status}</Text>
                  <Text>Sold Price: {listing.sold_price}</Text>
                  <Text>Sold Date: {listing.sold_date}</Text>
                  <Button mt={0} colorScheme="teal" size="xs" onClick={() => handleDrawerOpen(listing)}>
                    More Details
                  </Button>
                </VStack>
              </Popup>
            </Marker>
        ))}
      </MapContainer>

      {/* Table Output for Listings */}
      <Box mt={5} p={5} boxShadow="lg" borderRadius="md" bg="white">
        <Text fontSize="2xl" mb={5}>Zillow Listings</Text>
        <TableContainer>
          <Table variant="striped" colorScheme="teal">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Address</Th>
                <Th>Status</Th>
                <Th>Sold Price</Th>
                <Th>Sold Date</Th>
                <Th>Broker</Th>
                <Th>Details</Th>
              </Tr>
            </Thead>
            <Tbody>
              {listings.map((listing) => (
                <Tr key={listing.id}>
                  <Td>{listing.id}</Td>
                  <Td>{listing.address}</Td>
                  <Td>{listing.status}</Td>
                  <Td>{listing.sold_price}</Td>
                  <Td>{listing.sold_date}</Td>
                  <Td>{listing.broker}</Td>
                  <Td>
                    <Button size="sm" colorScheme="blue" onClick={() => handleDrawerOpen(listing)}>
                      View
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      {/* Drawer for more details */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{selectedListing?.address}</DrawerHeader>
          <DrawerBody>
            <Text><strong>Broker:</strong> {selectedListing?.broker}</Text>
            <Text><strong>Status:</strong> {selectedListing?.status}</Text>
            <Text><strong>Sold Price:</strong> {selectedListing?.sold_price}</Text>
            <Text><strong>Sold Date:</strong> {selectedListing?.sold_date}</Text>
            <Text><strong>Details:</strong> <a href={selectedListing?.detail_url} target="_blank" rel="noopener noreferrer">View Details</a></Text>
            <Text><strong>Image:</strong></Text>
            {selectedListing?.image_url && <img src={selectedListing?.image_url} alt="House" width="100%" />}
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default ZillowListings;
