import React, { useState, useEffect } from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  VStack,
  Select,
  Input,
  HStack,
  UnorderedList,
  ListItem,
  Image,
  Stack,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import axios from 'axios';
import ListingImage from './ListingImage';

// Define responsive container style
const getMapContainerStyle = isMobile => ({
  height: isMobile ? '300px' : '500px',
  width: '100%',
});

// Center coordinates for South Florida (around Miami)
const center = {
  lat: 25.7617, // Latitude for Miami, FL
  lng: -80.1918, // Longitude for Miami, FL
};

const getZoomLevel = isMobile => (isMobile ? 7 : 8); // Zoom out for both mobile and non-mobile to see all of Florida


// Function to generate Google Street View image URL
const getStreetViewUrl = (latitude, longitude, apiKey) => {
  return `https://maps.googleapis.com/maps/api/streetview?size=600x400&location=${latitude},${longitude}&key=${apiKey}`;
};

// Function to generate Google Static Map (satellite) image URL
const getSatelliteViewUrl = (latitude, longitude, apiKey) => {
  return `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=20&size=600x400&maptype=satellite&key=${apiKey}`;
};

const DatafinitiListings = () => {
  const [listings, setListings] = useState([]);
  const [filteredListings, setFilteredListings] = useState([]);
  const [geocodedListings, setGeocodedListings] = useState([]);
  const [selectedListing, setSelectedListing] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [mapRef, setMapRef] = useState(null);
  const geocodeCache = {}; // Cache geocode results to avoid redundant API calls

  // Google Maps API Key (replace with your key)
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  // Filter state
  const [bedroomFilter, setBedroomFilter] = useState('');
  const [bathroomFilter, setBathroomFilter] = useState('');
  const [priceFilter, setPriceFilter] = useState('');
  const [cityFilter, setCityFilter] = useState(''); // New state for city filter

  // State for detecting screen size
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Detect screen resize to adjust map responsiveness
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Fetch listings from the listings.json API endpoint
    axios
      .get('https://rebekah.cloud/api2/zillow_listings')
      .then(response => {
        const fetchedListings = response.data;
        setListings(fetchedListings);
        setFilteredListings(fetchedListings); // Initialize the filtered list
        geocodeListings(fetchedListings);
      })
      .catch(error => {
        console.error('Error fetching listings:', error);
      });
  }, []);

  const geocodeListings = async listings => {
    const geocodedData = [];

    for (const listing of listings) {
      const { address } = listing;

      // Check if the listing already has latitude and longitude
      if (listing.latitude && listing.longitude) {
        const lat = parseFloat(listing.latitude);
        const lng = parseFloat(listing.longitude);

        // Ensure lat/lng are valid numbers before adding to geocodedData
        if (!isNaN(lat) && !isNaN(lng)) {
          geocodedData.push({ ...listing, latitude: lat, longitude: lng });
        }
      } else {
        // Geocode the address using the Google Geocoding API
        if (!geocodeCache[address]) {
          try {
            const response = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json`,
              {
                params: {
                  address,
                  key: apiKey,
                },
              }
            );

            if (response.data.status === 'OK') {
              const { lat, lng } = response.data.results[0].geometry.location;
              geocodeCache[address] = { lat, lng }; // Cache the result
              geocodedData.push({ ...listing, latitude: lat, longitude: lng });
            } else {
              console.error('Geocode error: ', response.data.status);
            }
          } catch (error) {
            console.error('Error geocoding address:', error);
          }
        } else {
          // Use cached geocode result
          geocodedData.push({
            ...listing,
            latitude: geocodeCache[address].lat,
            longitude: geocodeCache[address].lng,
          });
        }
      }
    }

    setGeocodedListings(geocodedData);
  };

  // Filter function to update listings based on filters
  const applyFilters = () => {
    let filtered = [...listings];

    if (bedroomFilter) {
      filtered = filtered.filter(
        listing => listing.numBedroom === parseInt(bedroomFilter)
      );
    }
    if (bathroomFilter) {
      filtered = filtered.filter(
        listing => listing.numBathroom === parseInt(bathroomFilter)
      );
    }
    if (priceFilter) {
      filtered = filtered.filter(
        listing => listing.mostRecentPriceAmount <= parseInt(priceFilter)
      );
    }
    if (cityFilter) {
      // New city filter
      filtered = filtered.filter(listing => listing.city === cityFilter);
    }

    setFilteredListings(filtered);
  };

  useEffect(() => {
    applyFilters(); // Apply filters whenever filter state changes
  }, [bedroomFilter, bathroomFilter, priceFilter, cityFilter]);

  const handleDrawerOpen = listing => {
    setSelectedListing(listing);
    onOpen();
  };

  const handleMapLoad = map => {
    setMapRef(map);
  };

  const handleMarkerClick = listing => {
    setSelectedListing(listing);
  };

  // Extract unique cities from the listings to populate the city filter dropdown
  const uniqueCities = [...new Set(listings.map(listing => listing.city))];

  return (
    <>
      <LoadScript googleMapsApiKey={apiKey}>
        <GoogleMap
          mapContainerStyle={getMapContainerStyle(isMobile)} // Use responsive container style
          center={center}
          zoom={getZoomLevel(isMobile)} // Use responsive zoom level
          onLoad={handleMapLoad}
        >
          {geocodedListings
            .filter(listing => {
              const lat = parseFloat(listing.latitude);
              const lng = parseFloat(listing.longitude);
              return !isNaN(lat) && !isNaN(lng); // Ensure coordinates exist and are valid
            })
            .map(listing => (
              <Marker
                key={listing.id}
                position={{
                  lat: parseFloat(listing.latitude),
                  lng: parseFloat(listing.longitude),
                }}
                onClick={() => handleMarkerClick(listing)}
              />
            ))}

          {selectedListing &&
            !isNaN(parseFloat(selectedListing.latitude)) &&
            !isNaN(parseFloat(selectedListing.longitude)) && (
              <InfoWindow
                position={{
                  lat: parseFloat(selectedListing.latitude),
                  lng: parseFloat(selectedListing.longitude),
                }}
                onCloseClick={() => setSelectedListing(null)}
              >
                <div>
                  <VStack spacing={'auto'}>
                    <strong>{selectedListing.address}</strong>
                    <br />
                    <Text>Status: {selectedListing.mostRecentStatus}</Text>
                    <Text>Price: {selectedListing.mostRecentPriceAmount}</Text>
                    <Button
                      mt={0}
                      colorScheme="teal"
                      size="xs"
                      onClick={() => handleDrawerOpen(selectedListing)}
                    >
                      More Details
                    </Button>
                  </VStack>
                </div>
              </InfoWindow>
            )}
        </GoogleMap>
      </LoadScript>

      {/* Filter Section */}
      <Box
        p={{ base: 3, md: 5 }}
        mb={5}
        boxShadow="lg"
        borderRadius="md"
        bg="white"
      >
        <Text fontSize={{ base: 'xl', md: '2xl' }} mb={3}>
          Filter Listings
        </Text>
        <Stack spacing={5} direction={{ base: 'column', md: 'row' }}>
          <Select
            placeholder="Filter by Bedrooms"
            value={bedroomFilter}
            onChange={e => setBedroomFilter(e.target.value)}
          >
            <option value="1">1 Bedroom</option>
            <option value="2">2 Bedrooms</option>
            <option value="3">3 Bedrooms</option>
            <option value="4">4 Bedrooms</option>
          </Select>
          <Select
            placeholder="Filter by Bathrooms"
            value={bathroomFilter}
            onChange={e => setBathroomFilter(e.target.value)}
          >
            <option value="1">1 Bathroom</option>
            <option value="2">2 Bathrooms</option>
            <option value="3">3 Bathrooms</option>
          </Select>
          <Input
            placeholder="Max Price"
            type="number"
            value={priceFilter}
            onChange={e => setPriceFilter(e.target.value)}
          />
          <Select
            placeholder="Filter by City"
            value={cityFilter}
            onChange={e => setCityFilter(e.target.value)}
          >
            {uniqueCities.map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
          </Select>
        </Stack>
      </Box>

      <Box
        mt={1}
        boxShadow="lg"
        borderRadius="md"
        bg="white"
        p={{ base: 2, md: 5 }}
      >
        <Text fontSize={{ base: 'xl', md: '2xl' }} mb={5}>
          Listings
        </Text>

        <Grid
  templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
  gap={6}
>
  {filteredListings.map(listing => (
    <GridItem
      key={listing.id}
      border="1px solid #e2e8f0"
      borderRadius="md"
      overflow="hidden"
      boxShadow="lg"
      p={6}
    >
      <VStack align="start" spacing={1} height="100%">
        {/* Row 1: Image */}
        <Box width="100%" height="60%" mb={6}>
          <ListingImage
            latitude={listing.latitude}
            longitude={listing.longitude}
            apiKey={apiKey}
          />
        </Box>

        {/* Row 2: Status */}
        <HStack>
          {listing.mostRecentStatus === 'For Sale' && (
            <Box
              as="span"
              boxSize={2}
              bg="green.400"
              borderRadius="full"
              display="inline-block"
            />
          )}
          <Text fontSize="sm" color="gray.500">
            {listing.mostRecentStatus}
          </Text>
        </HStack>

        {/* Row 3: Price in bold */}
        <Text fontSize="lg" fontWeight="bold">
          $
          {new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 0,
          }).format(listing.mostRecentPriceAmount)}
        </Text>

        {/* Row 4: Bedrooms and Bathrooms */}
        <Text fontSize="sm">
          {listing.numBedroom}bd {listing.numBathroom}ba
        </Text>

        {/* Row 5: Address */}
        <Text fontSize="sm" isTruncated>
          {listing.address}
        </Text>

        {/* Row 6: City/State on left, View button on right */}
        <HStack mb={-2} mt={-1} justify="space-between" width="100%">
          <Text fontSize="sm">
            {listing.city}, {listing.province}
          </Text>
          <Button
            size="sm"
            colorScheme="blue"
            onClick={() => handleDrawerOpen(listing)}
          >
            View
          </Button>
        </HStack>
      </VStack>
    </GridItem>
  ))}
</Grid>


      </Box>

      {/* Drawer for more details */}
      <Drawer isOpen={isOpen} size="lg" placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            {selectedListing?.address} <br />
            <Text fontSize="sm" color="gray.500">
              ID: {selectedListing?.id}
            </Text>
          </DrawerHeader>
          <DrawerBody>
            {/* Street View Image */}
            {selectedListing?.latitude && selectedListing?.longitude && (
              <>
                <Text>
                  <strong>Street View:</strong>
                </Text>
                <Image
                  src={getStreetViewUrl(
                    selectedListing.latitude,
                    selectedListing.longitude,
                    apiKey
                  )}
                  alt="Street View"
                  mb={4}
                  borderRadius="md"
                />
              </>
            )}

            {/* Satellite View Image */}
            {selectedListing?.latitude && selectedListing?.longitude && (
              <>
                <Text>
                  <strong>Satellite View:</strong>
                </Text>
                <Image
                  src={getSatelliteViewUrl(
                    selectedListing.latitude,
                    selectedListing.longitude,
                    apiKey
                  )}
                  alt="Satellite View"
                  mb={4}
                  borderRadius="md"
                />
              </>
            )}

            <Text>
              <strong>Appliances:</strong>
            </Text>
            <UnorderedList>
              {selectedListing?.appliances?.map((appliance, index) => (
                <ListItem key={index}>{appliance}</ListItem>
              ))}
            </UnorderedList>
            <Text>
              <strong>Status:</strong> {selectedListing?.mostRecentStatus}
            </Text>
            <Text>
              <strong>Price:</strong>
              {selectedListing?.mostRecentPriceAmount
                ? `$${new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                  }).format(selectedListing.mostRecentPriceAmount)}`
                : 'N/A'}
            </Text>
            <Text>
              <strong>Bedrooms:</strong> {selectedListing?.numBedroom}
            </Text>
            <Text>
              <strong>Bathrooms:</strong> {selectedListing?.numBathroom}
            </Text>
            <Text>
              <strong>Description:</strong>
            </Text>
            {selectedListing?.descriptions?.length > 0 && (
              <Text>{selectedListing.descriptions[0].value}</Text>
            )}

            {/* Brokers Section */}
            <Text mt={4}>
              <strong>Brokers:</strong>
            </Text>
            {selectedListing?.brokers?.map((broker, index) => (
              <Box
                key={index}
                p={3}
                mb={3}
                borderWidth="1px"
                borderRadius="md"
                bg="gray.50"
              >
                <Text>
                  <strong>Agent:</strong> {broker.agent}
                </Text>
                <Text>
                  <strong>Company:</strong> {broker.company}
                </Text>
                <Text>
                  <strong>Date Seen:</strong>{' '}
                  {new Date(broker.dateSeen).toLocaleString()}
                </Text>
                {broker.emails && (
                  <>
                    <Text>
                      <strong>Emails:</strong>
                    </Text>
                    <UnorderedList>
                      {broker.emails.map((email, idx) => (
                        <ListItem key={idx}>{email}</ListItem>
                      ))}
                    </UnorderedList>
                  </>
                )}
                {broker.phones && (
                  <>
                    <Text>
                      <strong>Phones:</strong>
                    </Text>
                    <UnorderedList>
                      {broker.phones.map((phone, idx) => (
                        <ListItem key={idx}>{phone}</ListItem>
                      ))}
                    </UnorderedList>
                  </>
                )}
              </Box>
            ))}
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default DatafinitiListings;
