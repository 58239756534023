import React from 'react';
import {
  ChakraProvider,
  Box,
  Grid,
  VStack,
  theme,
  Image,
  Heading,
  HStack,
} from '@chakra-ui/react';
import SouthFloridaMap from './components/SouthFloridaMap';
import logo from './logo.png';
import ZillowListings from './components/ZillowListings';
import GoogleListings from './components/GoogleListings';
import DatafinitiListings from './components/DatafinitiListings';

function App() {
  
  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid >
          <Box bg="gray.200" p={6} shadow="md">
            <HStack justifyContent="space-between" alignItems="center">
              <HStack spacing={4} mt={-2}>
                <Image src={logo} alt="Logo" boxSize="50px" />
                <Heading as="h1" size="lg">Dockland</Heading>
                <Heading as="h3" size="sm">Your Gateway to Waterfront Living</Heading>
              </HStack>
              
            </HStack>
          </Box>

          <VStack spacing={2}>
            <Box width="100%" height="500px">
              {/**<SouthFloridaMap /> */}

              <DatafinitiListings />
            </Box>
          </VStack>
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default App;
